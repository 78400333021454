/* Home Page Styles */
.home-page {
  padding: 30px;
  background-color: #f8f9fa; /* Light background for contrast */
}




.user-photo-placeholder {
  width: 120px; /* Increased photo size */
  height: 120px; /* Increased photo size */
  border-radius: 12%;
  object-fit: cover;
  background-color: #e0e0e0; /* Light gray background */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999; /* Gray text color */
  font-size: 14px; /* Font size for placeholder text */
  font-weight: bold; /* Bold text */
  border: 2px solid #6b3f91; /* Optional border for visibility */
}

.welcome-message {
  font-size: 28px;
  margin-bottom: 15px;
  color: #c7003f;
  text-align: center; /* Center align */
}

.profile-completion {
  font-size: 20px;
  margin-bottom: 25px;
  text-align: center; /* Center align */
}

/* Photo Upload Section */
.photo-upload-section {
  margin: 25px 0;
  text-align: center; /* Center align */
}

.file-input {
  border: 1px dashed #6b3f91; /* Dashed border for emphasis */
  padding: 12px;
  border-radius: 8px;
  transition: border-color 0.3s;
}

.file-input:hover {
  border-color: #5a2d74; /* Change on hover */
}

.selected-photo-info {
  margin-top: 12px;
}
/* Buttons */
.save-button {
  background-color: #6b3f91; /* Save button color */
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s, transform 0.3s;
}

.save-button:hover {
  background-color: #5a2d74; /* Hover color for Save button */
  transform: translateY(-2px); /* Lift effect */
}

.cancell-button { /* Updated class name from 'cancell-button' to 'cancel-button' */
  background-color: red; /* Cancel button color */
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.cancell-button:hover { /* Corrected the class name to match */
  background-color: darkred; /* Hover color for Cancel button */
  transform: translateY(-2px); /* Lift effect */
}


/* Profile Photo */
.profile-photo-container {
  text-align: center; /* Center align */
  margin: 25px 0;
}

.profile-photo {
  width: 120px;
  height: 120px;
  border-radius: 20%; /* Circular photo */
  object-fit: cover;
  border: 2px solid #6b3f91; /* Thicker border */
}

.profile-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  color: #999;
}

/* Matches Section */
.matches-title {
  font-size: 24px;
  margin: 30px 0;
  font-family: 'poppins','sans-serif';
  color: #6b3f91;
  font-weight: 600;
  text-align: center; /* Center align */
}

.matches-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 25px;
}

.user-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px; /* Increased padding */
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.user-photo-container {
  margin-bottom: 15px;
}

.user-photo {
  width: 120px; /* Increased photo size */
  height: 120px; /* Increased photo size */
  border-radius: 12%;
  object-fit: cover;
  border: 2px solid #bd0045;
}

.user-details {
  text-align: left;
  padding: 15px;
  border-top: 1px solid #e0e0e0; /* Divider line */
  margin-top: 15px;
  background-color: #e9e3f6a2;
  border-radius: 8px;
}

.user {
  margin: 0 0 8px; /* Space between paragraphs */
  line-height: 1.5; /* Improved readability */
  color: #333; /* Dark text color */
  font-size: 16px; /* Slightly larger font size */
  font-family: 'poppins','sans-serif';
}

.user strong {
  color: #393838; /* Primary color for labels */
  font-weight: 600; /* Emphasized labels */
}

/* Responsive Styles */
@media (max-width: 600px) {
  

  .welcome-message {
    font-size: 22px;
  }

  .profile-photo {
    width: 140px;
    height: 140px;
  }

  .user-photo {
    width: 140px;
    height: 140px;
  }

  .user-card {
    padding: 15px; /* Adjusted padding for smaller screens */
  }
}
.user-name {
  font-size: 20px;
  color: #c92403;          /* Default font size */
  font-weight: 600;       /* Darker text color for readability */
  margin: 10px 0;          /* Vertical margin for spacing */
  font-family: 'poppins','sans-serif'; /* Clean font style */
}


/* Mobile Responsive Styles */
@media (max-width: 480px) {
  .user-name {
    font-size: 18px;        /* Smaller font size for mobile */
    margin: 8px 0;         /* Adjusted margin for smaller screens */
  }
}
.verification-container {
  background-color: #fdfcf5; /* Light background for contrast */
  
  border-radius: 4px; /* Rounded corners */
  padding: 30px; /* Inner padding */
  text-align: center; /* Center the text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 1200px; /* Max width for larger screens */
  margin: 30px auto; /* Center it horizontally with some margin */
  font-family: 'poppins','sans-serif';
}

.verification-container h1 {
  font-size: 1.8rem; /* Larger font size for the heading */
  color: #a70e37; /* Dark text color */
  font-weight: 600;
  margin-bottom: 10px; /* Space below the heading */
}

.verification-container p {
  font-size: 1rem; /* Base font size for paragraph */
  color: #555; /* Slightly lighter text color */
}

/* Responsive styles */
@media (max-width: 600px) {
  .verification-container {
      padding: 15px; /* Less padding on smaller screens */
      max-width: 85%; /* Full width on smaller screens */
  }

  .verification-container h1 {
      font-size: 1.5rem; /* Smaller font size for heading */
  }

  .verification-container p {
      font-size: 0.9rem; /* Smaller font size for paragraph */
  }
}



.profile-button {
  background-color: #008b6f; /* Turquoise Green */
  color: white;
  border: none;
 
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'poppins','sans-serif';
  transition: background-color 0.3s ease;
}

.profile-button:hover {
  background-color: #16a085; /* Darker shade for hover */
}
