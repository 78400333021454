/* src/styles/Footer.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&family=Merriweather:wght@400;700&display=swap');

/* Footer Container */
.footer-container {
    background-color: #202121; /* Dark Blue */
    color: #f1f1f1;
    font-family: 'Nunito', sans-serif;
    padding: 20px 10px; /* Reduced padding for mobile */
    width: 100%;
    box-sizing: border-box;
}

/* Footer Content */
.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Keep space between items */
    gap: 10px; /* Reduced gap */
    max-width: 1200px;
    margin: 0 auto;
}

/* Footer Sections */
.footer-section {
    flex: 1 1 150px; /* Flex basis for mobile */
    margin-bottom: 15px; /* Reduced margin */
    text-align: left; /* Align text to the left */
}

/* About Section */
.footer-section.about h2 {
    font-family: 'Merriweather', serif;
    margin-bottom: 5px; /* Reduced margin */
    color: #1abc9c; /* Turquoise Green */
    font-size: 1.4rem; /* Smaller font size */
}

.footer-section.about p {
    font-size: 0.9rem; /* Smaller font size */
    line-height: 1.4; /* Reduced line height */
}

/* Quick Links Section */
.footer-section.links h3 {
    font-family: 'Merriweather', serif;
    margin-bottom: 5px; /* Reduced margin */
    color: #1abc9c; /* Turquoise Green */
    font-size: 1.3rem; /* Smaller font size */
}

.footer-section.links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-section.links ul li {
    margin-bottom: 5px; /* Reduced margin */
}

.footer-section.links ul li a {
    color: #f1f1f1;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 0.9rem; /* Smaller font size */
}

.footer-section.links ul li a:hover {
    color: #1abc9c; /* Turquoise Green */
    text-decoration: underline;
}

/* Contact Section */
.footer-section.contact h3 {
    font-family: 'Merriweather', serif;
    margin-bottom: 5px; /* Reduced margin */
    color: #1abc9c; /* Turquoise Green */
    font-size: 1.3rem; /* Smaller font size */
}

.footer-section.contact p {
    margin-bottom: 5px; /* Reduced margin */
    font-size: 0.9rem; /* Smaller font size */
}

.footer-section.contact a {
    color: #f1f1f1;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section.contact a:hover {
    color: #1abc9c; /* Turquoise Green */
}

/* Social Media Section */
.footer-section.social-media h3 {
    font-family: 'Merriweather', serif;
    margin-bottom: 5px; /* Reduced margin */
    color: #1abc9c; /* Turquoise Green */
    font-size: 1.3rem; /* Smaller font size */
}

.social-icons {
    display: flex;
    gap: 10px; /* Reduced gap */
    justify-content: flex-start; /* Align social icons to the left */
}

.social-icons a {
    color: #f1f1f1;
    font-size: 1.3rem; /* Slightly smaller font size */
    transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
    color: orange; /* Turquoise Green */
    transform: scale(1.1); /* Reduced scale effect */
}

/* Footer Bottom */
.footer-bottom {
    text-align: center;
    margin-top: 20px; /* Reduced margin */
    border-top: 1px solid #444;
    padding-top: 10px; /* Reduced padding */
    font-size: 0.75rem; /* Smaller font size */
    color: #aaa;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the start */
    }

    .footer-section {
        margin-bottom: 10px; /* Reduced margin */
        width: 100%;
        max-width: 400px; /* Smaller max-width */
        text-align: left; /* Align text to the left */
    }

    /* Social icons left aligned for mobile */
    .social-icons {
        justify-content: flex-start; /* Align social icons to the left */
    }

    /* Larger touch areas for mobile */
    .footer-section.links ul li,
    .footer-section.contact p {
        font-size: 0.85rem; /* Even smaller font size */
    }
}
