/* Main Container */
.lion-container {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa; /* Light background for better contrast */
    color: #333; /* Dark text color for readability */
}

.eagle-header {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center items vertically */
    padding: 20px; /* Padding around the header */
    background-color: #9c0630; /* Light background color */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.eagle-header h1 {
    font-size: 1.5rem; /* Size of the header text */
    margin: 0; /* Remove default margin */
    color: #ffffff; /* Dark color for contrast */
    font-family: 'poppins','sans-serif';
    font-weight: 500;
}


.tiger-auth-buttons {
    display: flex; /* Use flexbox for buttons layout */
    gap: 15px; /* Space between buttons */
}

.elephant-login-button, .giraffe-register-button {
    padding: 10px 20px; /* Padding inside buttons */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Change cursor to pointer */
    font-family: 'poppins','sans-serif';
    font-weight: 400;
    font-size: 1rem;
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.elephant-login-button {
    background-color: #007bff; /* Primary button color */
    color: white; /* White text */
}

.elephant-login-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.giraffe-register-button {
    background-color: #28a745; /* Success button color */
    color: white; /* White text */
}

.giraffe-register-button:hover {
    background-color: #218838; /* Darker shade on hover */
}.dd {
    display: flex;               /* Use flexbox for alignment */
    justify-content: center;     /* Center horizontally */
    align-items: center;         /* Center vertically */
   padding: 30px;          /* Full viewport height */
}

.tiger-auth-buttons {
    display: flex;               /* Flexbox for the button container */
    gap: 10px;                  /* Space between buttons */
}


/* Mobile Responsiveness */
@media (max-width: 768px) {
    .eagle-header {
        flex-direction: column; /* Stack items on smaller screens */
        align-items: flex-start; /* Align items to the left */
    }

    .tiger-auth-buttons {
        margin-top: 10px; /* Add margin above buttons */
    }
}


/* Hero Section */
.whale-hero-section {
    text-align: center;
    padding: 40px 20px;
}

.whale-hero-section h2 {
    font-size: 2rem; /* Font size for the hero section heading */
    margin-bottom: 20px; /* Space below heading */
    color: #2d2d2d; /* Dark color for readability */
}
.whale-hero-section h1 {
    
    margin-bottom: 20px; /* Space below heading */
    color: #e74c3c; /* Dark color for readability */
}

.penguin-hero-image {
    max-width: 40%; /* Responsive image */
    height: auto; /* Maintain aspect ratio */
    margin: 20px 0; /* Margin above and below the image */
    border-radius: 10px; /* Rounded corners for the image */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}
/* Mobile Responsiveness */
@media (max-width: 768px) { /* Adjust the breakpoint as needed */
    .penguin-hero-image {
        max-width: 80%; /* Increase width for mobile screens */
        margin: 10px 0; /* Reduce margin for mobile screens */
    }
}
.koala-hero-description {
    font-size: 1.1rem; /* Font size for hero description */
    max-width: 600px; /* Limit width for better readability */
    margin: auto; /* Center the description */
    line-height: 1.5; /* Line height for readability */
}

/* Achievements Section */
.fox-achievements {
    text-align: center;
    padding: 40px 20px;
}

.fox-achievements h3 {
    font-size: 1.8rem; /* Font size for achievements heading */
    margin-bottom: 20px; /* Space below heading */
}

.achievements-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Wrap items on small screens */
    gap: 20px; /* Space between items */
}

.owl-achievement-item,
.bear-achievement-item {
    flex: 1;
    min-width: 200px; /* Ensure a minimum width */
    text-align: center; /* Center text */
}

.parrot-achievement-image,
.dolphin-achievement-image {
    max-width: 100%; /* Responsive images */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Rounded corners for images */
}

/* Services Section */



.services-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Wrap items on small screens */
    gap: 20px; /* Space between items */
}

.zebra-service-item,
.kangaroo-service-item,
.rabbit-service-item {
    flex: 1;
    min-width: 200px; /* Ensure a minimum width */
    background: white; /* White background for service items */
    padding: 20px;
    border-radius: 10px; /* Rounded corners for service items */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.zebra-service-item h4,
.kangaroo-service-item h4,
.rabbit-service-item h4 {
    font-size: 1.5rem; /* Font size for service titles */
    margin: 10px 0; /* Space above and below titles */
    color: #e74c3c; /* Blue color for titles */
}

.rabbit-service-item img{
    width: 300px;
    height: auto;
    pointer-events: none; 
    user-select: none;  
    -webkit-user-drag: none;         
}
.kangaroo-service-item img{
    width: 300px;
    height: auto;
    pointer-events: none; 
    user-select: none;   
    -webkit-user-drag: none;        
}
.zebra-service-item img{
    width: 300px;
    height: auto;
    pointer-events: none; 
    user-select: none;  
    -webkit-user-drag: none;         
}
/* Testimonials Section */
.turtle-testimonials {
    padding: 40px 20px;
}

.turtle-testimonials h3 {
    font-size: 1.8rem; /* Font size for testimonials heading */
    margin-bottom: 20px; /* Space below heading */
}

.testimonials-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Wrap items on small screens */
    gap: 20px; /* Space between items */
}

.horse-testimonial-item,
.cat-testimonial-item {
    flex: 1;
    min-width: 200px; /* Ensure a minimum width */
    background: white; /* White background for testimonials */
    padding: 20px;
    border-radius: 10px; /* Rounded corners for testimonials */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.horse-testimonial-item h4,
.cat-testimonial-item h4 {
    font-size: 1.5rem; /* Font size for testimonial titles */
    margin: 10px 0; /* Space above and below titles */
    color: #e74c3c; /* Blue color for titles */
}

/* Call to Action Section */
.swan-cta {
    text-align: center;
    padding: 40px 20px;
    background-color: #2c205c; /* CTA background color */
    color: white;
}

.swan-cta h3 {
    font-size: 1.8rem; /* Font size for CTA heading */
    margin-bottom: 10px; /* Space below heading */
}

.swan-cta p {
    font-size: 1.2rem; /* Font size for CTA paragraph */
    margin-bottom: 20px; /* Space below paragraph */
}

.dove-cta-button {
    padding: 10px 20px;
    border: none;
    font-weight: 500;
    border-radius: 5px;
    background-color: #07ff9c; /* Contrasting button color */
  
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'poppins','sans-serif';
    font-size: 1rem; /* Font size for CTA button */
}

.dove-cta-button:hover {
    background-color: #5affbd; /* Darker shade on hover */
}

/* Footer */
.footer {
    padding: 20px;
    text-align: center;
    background-color: #343a40; /* Dark footer */
    color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .lion-container {
        padding: 10px; /* Add padding for smaller screens */
    }

    .eagle-header h1 {
        font-size: 2rem; /* Reduce header size */
    }

    .whale-hero-section h2 {
        font-size: 1.8rem; /* Reduce size for hero section */
    }

    .fox-achievements h3,
    .giraffe-services h3,
    .turtle-testimonials h3 {
        font-size: 1.5rem; /* Reduce heading sizes */
    }

    .swan-cta h3 {
        font-size: 1.5rem; /* Reduce CTA heading size */
    }

    .giraffe-services,
    .turtle-testimonials {
        padding: 20px 10px; /* Reduce padding for smaller screens */
    }
}
.custom-link {
    text-decoration: none; /* Remove underline from link */
}

.custom-button {
    background-color: #ea8500; /* Green background */
    color: white; /* Text color */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    padding: 10px 20px; /* Top/Bottom, Left/Right padding */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.custom-button:hover {
    background-color: #ea8500; /* Darker green on hover */
}
.review-container {
    text-align: center;
   
    padding: 20px;
    background-color: #ffffff;
  }
  .review-container img{
    width: 300px;
    height: auto;
    pointer-events: none; 
    user-select: none;   
    -webkit-user-drag: none; 
  }
  .review-container h2{
    color: #e74c3c;
  }
  
  
  .rate-us-button {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    padding: 10px 20px; /* Some padding */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .rate-us-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  /* Achievements Section Styles */
.achievement-section {
    background-color: #f9f9f9; /* Light background color */
    padding: 40px 20px; /* Padding for top/bottom and left/right */
    text-align: center; /* Center-align text */
  }
  
  .achievement-section h3 {
    font-size: 24px; /* Font size for the heading */
    color: #2c3e50; /* Dark color for the heading */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .achievement-section p {
    font-size: 16px; /* Font size for the paragraph */
    color: #34495e; /* Slightly lighter dark color */
    line-height: 1.6; /* Better line spacing for readability */
    max-width: 800px; /* Max width for paragraph */
    margin: 0 auto; /* Center the paragraph */
  }
  
  .achievement-section strong {
    color: #e74c3c; /* Highlight color for important text */
    font-weight: bold; /* Make it bold */
  }
  .achievement-content img, .achievement-content svg {
    width: 300px; /* Set a fixed width for the SVG or image */
    height: 300px; /* Set a fixed height for the SVG or image */
    pointer-events: none; 
    user-select: none;   
    -webkit-user-drag: none; 
    
  }
  .download-app-section {
    background-color: #f1fcff; /* Light background for contrast */
    padding: 20px;
    text-align: center;
  
    margin: 20px 0;
  }
  
  .download-app-section h3 {
    font-size: 24px;
    color: #e74c3c; /* A strong color for attention */
    margin-bottom: 10px;
  }
  
  .download-app-section p {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .download-app-section a {
    display: inline-block;
    text-decoration: none;
  }
  
  .google-play-button {
    width: 150px;
    height: auto;
    transition: transform 0.9s ease; /* Add a hover effect */
  }
  
  .google-play-button:hover {
    transform: scale(1.1); /* Scale up the button on hover */
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .download-app-section h3 {
      font-size: 20px;
    }
  
    .download-app-section p {
      font-size: 14px;
    }
  
    .google-play-button {
      width: 130px;
    }
  }
  .giraffe-services {
    text-align: center;
    padding: 40px 20px;
    background-color: #fff1de;
  }
  
  .giraffe-services h3 {
    font-size: 28px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .giraffe-services h3 svg {
    margin-right: 10px; /* Adds space between the icon and text */
    color: #e74c3c; /* Change this to your preferred color */
    
  }
  
  .service-icon {
    width: 300px;  /* Adjust the size to fit your layout */
    height: auto;
    pointer-events: none; 
    user-select: none;   
    -webkit-user-drag: none; 
    
  }
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #16bb52; /* WhatsApp Green */
  color: white;
  border: none;
  border-radius: 30px; /* Slightly rounded for a pill shape */
  padding: 10px 15px; /* Adjust padding for better spacing */
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Center vertically */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 1000; /* Ensure it is on top of other elements */
  transition: transform 0.3s;
}

.whatsapp-button:hover {
  transform: scale(1.05); /* Scale up on hover */
}

.whatsapp-label {
  margin-left: 8px; /* Space between icon and text */
  font-size: 16px; /* Adjust font size */
  font-weight: bold; /* Make the text bold */
 
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .whatsapp-button {
    bottom: 15px; /* Reduce bottom margin */
    right: 15px; /* Reduce right margin */
    padding: 8px 10px; /* Adjust padding */
  }

  .whatsapp-label {
    font-size: 14px; /* Smaller font size on mobile */
  }
}

/* Extra mobile responsiveness for smaller screens */
@media (max-width: 480px) {
  .whatsapp-button {
    padding: 6px 8px; /* Further adjust padding for very small screens */
  }

  .whatsapp-label {
    font-size: 12px; /* Further reduce font size */
  }
}
