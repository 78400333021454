
.aw {
    max-width: 1400px;
    margin: 20px auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.ah {
    text-align: center;
    margin-bottom: 20px;
}

.ahg {
    font-size: 2.5em;
    color: #2c3e50;
    margin: 0;
}

.ahs {
    font-size: 1.5em;
    color: #c94e2f;
    margin: 10px 0;
}

.at {
    font-size: 1.1em;
    color: #34495e;
    margin: 10px 0 30px;
}

.ai {
    margin-top: 20px;
}

.as {
    margin-bottom: 30px;
}

.sh {
    font-size: 2em;
    color: #e74c3c;
    margin-bottom: 10px;
}

.st {
    font-size: 1.1em;
    color: #555;
    line-height: 1.6;
}

.af {
    margin-bottom: 20px;
}

.fl {
    list-style-type: disc;
    padding-left: 20px;
}

.fi {
    font-size: 1.1em;
    margin: 5px 0;
    color: #555;
}

.contact-section {
    background-color: #fff6ef;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin-top: 30px;
}

.section-title {
    font-size: 1.5em;
    color: hsl(331, 85%, 36%);
}

.section-text {
    font-size: 1.1em;
    color: #34495e;
}

.contact-link {
    color: #e74c3c;
    text-decoration: underline;
    font-weight: bold;
}

.contact-link:hover {
    text-decoration: underline;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .aw {
        padding: 15px;
    }

    .ahg {
        font-size: 2em;
    }

    .ahs {
        font-size: 1.3em;
    }

    .at {
        font-size: 1em;
    }

    .ai {
        margin-top: 15px;
    }

    .sh {
        font-size: 1.8em;
    }

    .st {
        font-size: 1em;
    }

    .section-title {
        font-size: 1.5em;
    }

    .section-text {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .ahg {
        font-size: 1.8em;
    }

    .ahs {
        font-size: 1.2em;
    }

    .at {
        font-size: 0.9em;
    }

    .sh {
        font-size: 1.6em;
    }

    .fi {
        font-size: 1em;
    }

    .section-title {
        font-size: 1.3em;
    }

    .section-text {
        font-size: 0.9em;
    }
}
