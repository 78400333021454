

/* Main container for deleting account */
.hero-delete-account-container {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    border-radius: 12px; /* Rounded corners */
    background-color: #ffffff; /* White background */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center;
}

.hero-delete-account-container h2 {
    font-size: 26px;
    margin-bottom: 10px;
    color: #333; /* Dark text */
}

.hero-delete-account-container p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555; /* Slightly lighter text */
}

/* Button styles */
.hero-delete-button {
    background-color: #dc3545; /* Bootstrap danger color (red) */
    color: white;
    border: none;
    border-radius: 8px; /* Rounded corners */
    padding: 14px 20px; /* Padding */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Full width */
}

.hero-delete-button:hover {
    background-color: #c82333; /* Darker shade on hover */
}

.hero-confirm-button {
    background-color: #e61025; /* Bootstrap success color (green) */
    color: white;
    border: none;
    border-radius: 8px; /* Rounded corners */
    padding: 14px 20px; /* Padding */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Full width */
    margin-top: 10px; /* Spacing from previous button */
}

.hero-confirm-button:hover {
    background-color: #c82333; /* Darker shade on hover */
}

.hero-cancel-button {
    background-color: #434037; /* Bootstrap warning color (yellow) */
    color: white;
    border: none;
    border-radius: 8px; /* Rounded corners */
    padding: 14px 20px; /* Padding */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Full width */
    margin-top: 10px; /* Spacing from previous button */
}

.hero-cancel-button:hover {
    background-color: #36342e; /* Darker shade on hover */
}

/* Modal overlay styles */
.hero-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Modal styles */
.hero-modal {
    background-color: white;
    border-radius: 4px;
    padding: 25px; /* Padding inside modal */
    max-width: 400px;
    width: 90%; /* Responsive width */
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.hero-modal h3 {
    font-size: 20px;
    margin-bottom: 15px;
}

.hero-modal p {
    font-size: 16px;
    margin-bottom: 20px;
}


/* Responsive styles */
@media (max-width: 600px) {
    .hero-delete-account-container h2 {
        font-size: 22px; /* Smaller header on mobile */
    }

    .hero-delete-account-container p {
        font-size: 14px; /* Smaller paragraph on mobile */
    }

    .hero-delete-button,
    .hero-confirm-button,
    .hero-cancel-button {
        font-size: 14px; /* Smaller font on buttons */
        padding: 12px 0; /* Adjust button padding for smaller screens */
    }
}

.password-input {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
    font-size: 16px;
  }
  
  .password-input:focus {
    outline: none;
    border-color: #007bff; /* Focus color */
  }
  