/* General styling */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #04708e; /* Matrimony themed color */
  padding: 15px 25px;
  position: relative;
  
}

.navbar-brand {
  font-size: 1.8rem;
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-family: 'poppins','sans-serif';
  cursor: pointer;
  
 
}

.navbar-links {
  display: flex;
  gap: 20px;
}

.navbar-links .more-button {
  
  border: none;
  padding: 10px;
  cursor: pointer;
}

.more-menu {
  position: absolute;
  top: 105%;
  right: 0;
  background-color: #04708e; /* Adjust color as needed */
 width: 220px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 16px;
}

.more-menu button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: white;
  text-align: left;
  
}

.more-menu button:hover {
  background-color: #ff2a2a; /* Darker color on hover */
}
.navbar-links button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.1rem;
  font-size: 1.0rem; /* Decrease text size for mobile view */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 8px 15px;
  font-family: 'Poppins', sans-serif; /* Apply Poppins font */
  border-radius: 8px;
}


.navbar-links button:hover {
  background-color: rgb(255, 231, 133); /* Hover with transparency */
  color: #171717; /* Gold hover effect */
}
.hamburger {
  display: none;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: 0.3s;
}

/* Responsive behavior */
@media (max-width: 768px) {
  .navbar-links {
    position: absolute;
    top: 68px;
    right: 0;
    flex-direction: column;
    background-color: #04708e; /* Adjust the color as needed */
    width: 50%;
  
    display: none;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow effect */
  }


  .navbar-links.open {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .navbar-links button {
    padding: 5px 10px;
    width: 100%;
    text-align: left;
    font-size: 1.0rem; /* Decrease text size for mobile view */
  }
}
