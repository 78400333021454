.photo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure modal is above other content */
  overflow: hidden; /* Prevent overflow */
}

.photo-modal-content {
  position: relative;
  max-width: 600px; /* Set a maximum width for desktop */
  max-height: 80%; /* Limit height to keep it within view */
  background: white; /* White background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
  overflow: hidden; /* Prevent content overflow */
}

.photo-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
  font-size: 2rem;
  color: rgb(86, 77, 77); /* Adjust this if you need better contrast */
  cursor: pointer;
  z-index: 1001; /* Ensure close button is above modal content */
}

.photo-modal-image {
  width: 100%;
  height: auto;
  max-height: 80vh; /* Maximum height of photo relative to viewport */
  object-fit: cover; /* Cover the area without distortion */
  pointer-events: none; /* Prevent drag-and-drop and interactions */
 
  -webkit-user-drag: none; /* Disable dragging in Safari */
}

/* Responsive Styles for Modal */
@media (max-width: 768px) {
  .photo-modal-content {
      max-width: 90%; /* Slightly smaller on tablets */
  }
}

@media (max-width: 480px) {
  .photo-modal-content {
      max-width: 95%; /* Smaller on mobile */
  }

  .photo-modal-close-button {
      font-size: 1.5rem; /* Smaller close button on mobile */
  }
}
