.logsucc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .success-message {
    text-align: center;
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .success-message h1 {
    color: #4CAF50;
    font-size: 2rem;
  }
  
  .success-message p {
    color: #333;
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .success-message {
    text-align: center;
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .success-message h1 {
    color: #4CAF50;
    font-size: 2rem;
   
    margin-left: 10px;
  }
  
  .success-icon {
    color: #4CAF50;
    font-size: 3rem;
    vertical-align: middle;
  }
  