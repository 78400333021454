/* src/Styles/Subscription.css */

/* Main subscription page styles */
.subscription-page {
    padding: 20px;
    
  }
  
 /* Importing Google Fonts for a clean look */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

.subscription-content {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
 
  font-family: 'Poppins', sans-serif; /* Applied Poppins font family */
  max-width: 600px; /* Center and limit width */
  margin: 20px auto; /* Centering the content */
}

.subscription-title {
  text-align: center;
  font-size: 2.5rem; /* Larger title size for impact */
  margin-bottom: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: rgb(210, 1, 88);
  font-weight: 600; /* Bold title */
}

.membership-status {
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth hover effects */
}

.paid {
  background-color: #e6ffed; /* Light green for paid members */
  color: #0b0b0b; /* Dark green text */
  border-left: 5px solid #2ecc71; /* Green border for visual emphasis */
}

.free {
  background-color: #e6f7ff; /* Light blue for free members */
  color: #000000; /* Dark blue text */
  border-left: 5px solid #1abc9c; /* Teal border for visual emphasis */
}



.status-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-weight: 600; /* Medium weight for status title */
}

.benefits-list {
  margin: 15px 0;
  padding: 0;
  list-style-type: none;
}

.benefits-list li {
  margin: 10px 0;
  display: flex;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  align-items: center; /* Align icons with text */
  font-size: 1.1rem; /* Slightly larger text for readability */
  color: #333; /* Light gray for benefits text */
}

.benefits-list li .fa {
  margin-right: 10px; /* Space between icon and text */
  color: #2980b9; /* Icon color */
  transition: color 0.3s; /* Smooth color transition */
}

.benefits-list li:hover .fa {
  color: #2c3e50; /* Darker icon color on hover */
}

.not-available {
  color: #e74c3c; /* Red for unavailable features */
  font-size: 0.9em; /* Smaller font size for notes */
}



/* Responsive design for smaller screens */
@media (max-width: 480px) {
  .subscription-title {
    font-size: 2rem; /* Adjust title size */
  }

  .status-title {
    font-size: 1.5rem; /* Adjust status title size */
  }

  .upgrade-button {
    width: 100%; /* Full-width button */
  }
}

  /* Button styles */
  .upgrade-button {
    background-color: #007787; /* Primary Color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  /* Flex container for button spacing */
  .button-container {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Space between buttons */
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .modal-message {
    margin-bottom: 15px;
  }
  
  /* Loading styles */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    border: 8px solid #f3f3f3; /* Light gray */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-text {
    margin-left: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .login-message {
    text-align: center;
    color: #999;
    font-size: 16px;
  }
  