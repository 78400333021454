/* styles/TermsConditions.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&family=Merriweather:wght@400;700&display=swap');

/* Container Styling */
.terms-container {
   
    background-color: #f9f9f9;
    padding: 50px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    font-family: 'Nunito', sans-serif;
    color: #2f2f2f;
    line-height: 1.8;
    transition: all 0.3s ease;
}

.terms-container:hover {
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

/* Header Styling */
.terms-header h1 {
    text-align: center;
    color: #1c1c1e;
    font-size: 2.8rem;
    font-family: 'Merriweather', serif;
    margin-bottom: 20px;
}

/* Effective Date Styling */
.terms-effective-date p {
    text-align: center;
    color: #626567;
    margin-bottom: 40px;
    font-size: 1.15rem;
    font-style: italic;
}

/* Section Styling */
.terms-section {
    margin-bottom: 40px;
}

/* Section Header Styling */
.section-header h2 {
    color: #bd0045;
    font-size: 1.85rem;
    margin-bottom: 15px;
    font-family: 'Nunito', sans-serif;
    border-bottom: 3px solid #eaecee;
    padding-bottom: 8px;
}

/* Section Content Styling */
.section-content p {
    line-height: 1.85;
    margin-bottom: 20px;
    font-size: 1.1rem;
    text-align: justify;
}

/* List Styling within Sections */
.terms-section ul {
    list-style-type: square;
    padding-left: 30px;
    margin-bottom: 20px;
}

.terms-section li {
    margin-bottom: 12px;
    font-size: 1.05rem;
}

/* Link Styling */
.terms-container a {
    color: #16a085;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
}

.terms-container a:hover {
    color: #0e6655;
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
    .terms-container {
        padding: 30px;
       
    }

    .terms-header h1 {
        font-size: 2.3rem;
    }

    .section-header h2 {
        font-size: 1.6rem;
    }

    .section-content p, 
    .terms-section li {
        font-size: 1rem;
    }
}
