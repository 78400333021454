.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    text-align: center;
    background-color: #ffffff; /* White background for a card effect */
    color: #343a40; /* Dark text color */
    padding: 40px;
    margin: 0; /* Remove default margin */
    border-radius: 10px; /* Rounded corners */
   
    transition: background-color 0.3s ease; /* Smooth background transition */
  }
  
 
  
  .not-found-image {
    max-width: 100%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
    max-width: 300px; /* Limit image size on larger screens */
    margin-bottom: 20px; /* Space between image and text */
    border-radius: 8px; /* Rounded corners for image */
  }
  
  .not-found-heading {
    font-size: 2.5rem; /* Heading size */
    margin-bottom: 10px; /* Space below heading */
    color: #dc3545; /* Emphasis color */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Slight text shadow for depth */
  }
  
  .not-found-paragraph {
    font-size: 1.2rem; /* Paragraph size */
    max-width: 600px; /* Limit width of the paragraph */
    margin: 5px 0; /* Space between paragraphs */
    line-height: 1.5; /* Improved line height for readability */
  }
  
  /* Link styling */
  .not-found-home-link {
    margin-top: 20px; /* Space above link */
    padding: 10px 20px; /* Padding for button */
    background-color: #007bff; /* Button color */
    color: white; /* Text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners for button */
    text-decoration: none; /* Remove underline */
    font-size: 1.2rem; /* Button text size */
    transition: background-color 0.3s ease; /* Smooth transition for hover */
  }
  
  .not-found-home-link:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .not-found-heading {
      font-size: 2rem; /* Smaller heading on mobile */
    }
  
    .not-found-paragraph {
      font-size: 1rem; /* Smaller paragraph text on mobile */
    }
  
    .not-found-image {
      max-width: 80%; /* Allow more width for images on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .not-found-heading {
      font-size: 1.5rem; /* Further reduce heading size */
    }
  
    .not-found-paragraph {
      font-size: 0.9rem; /* Further reduce paragraph text size */
    }
  }
  