/* Styles/Login.css */

.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
background-color: rgb(219, 245, 255);
  padding: 0 15px; /* Add padding for mobile screens */
}

.login-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 400px; /* Max width for larger screens */
  text-align: center;
}

.login-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.login-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none; 
    user-select: none;   
    -webkit-user-drag: none; 
}
.login-image-containers {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
           /* Full viewport height for vertical centering */
}

.login-images {
  max-width: 100%;        /* Ensures the image resizes properly */
  height: auto;           /* Maintains aspect ratio */
  pointer-events: none; 
    user-select: none;   
    -webkit-user-drag: none; 
}

.login-heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.login-input {
  width: 90%;
  padding: 12px 15px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.login-input:focus {
  outline: none;
  border-color: #f12711;
}

.login-button {
  width: 100%;
  padding: 12px 15px;
  background-color: #f12711;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #e6230d;
}

.login-links {
  margin-top: 20px;
}

.login-link {
  text-decoration: none;
  color: #f12711;
  font-weight: 500;
}

.login-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 600px) {
  .login-form {
    padding: 30px 20px; /* Reduce padding on mobile */
  }

  .login-heading {
    font-size: 22px; /* Slightly smaller font for headings on mobile */
  }

  .login-input {
    font-size: 18px; /* Adjust input font size for mobile */
    padding: 10px 12px; /* Reduce padding inside input fields */
  }

  .login-button {
    padding: 12px 12px; /* Reduce button padding on mobile */
    font-size: 16px; /* Adjust button font size */
  }
}
