
.profile-title {
  font-size: 2.0rem; 
  color: #bd0045; 
  text-align: center; 
  margin: 1.5rem 0; 
  font-weight: bold; 
  letter-spacing: 1px; 
  border-bottom: 2px solid #d3d3d3; 
  padding-bottom: 0.5rem; 
  transition: color 0.3s ease; 
}

.personal-info-container {
  background-color: #046978; 
  color: white;
  border-radius: 8px; 
 
  padding: 10px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  margin-bottom: 20px; 
  border: 1px solid #e0e0e0; 
}

.personal-info-container h2 {
  color: #ffffff;
}

.profile-container {
  max-width: 800px;
  margin: 20px auto; 
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-section {
  margin-bottom: 20px;
}

.profile-input,
.profile-select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  font-family: 'poppins','sans-serif';
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.profile-input:focus,
.profile-select:focus {
  border-color: #007bff;
  outline: none; 
}

/* Button Styles */
.update-button {
  background-color: #28a745; 
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.cancel-button {
  background-color: #dc3545; 
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.edit-button {
  background-color: #007bff; 
  color: rgb(255, 255, 255); 
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Button Hover Effects */
.update-button:hover {
  background-color: #218838; 
}

.cancel-button:hover {
  background-color: #c82333; 
}

.edit-button:hover {
  background-color: #0056b3; 
}

/* Loading spinner */
.loading-container {
  text-align: center;
  padding: 50px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* Animation for loading spinner */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Mobile Responsive Styles */
@media (max-width: 600px) {
  .profile-title {
    font-size: 1.5rem; 
  }

  .profile-container {
    padding: 15px;
    margin: 10px;
  }

  .personal-info-container {
    padding: 8px;
  }

  .profile-input,
  .profile-select {
    padding: 8px;
  }

  .update-button,
  .cancel-button,
  .edit-button {
    width: 100%; /* Full-width buttons on small screens */
    padding: 10px;
    margin: 5px 0; /* Space between buttons */
  }

  h1,
  h2 {
    font-size: 1.5em;
  }
}

/* Tablet Responsive Styles */
@media (min-width: 601px) and (max-width: 1024px) {
  .profile-container {
    padding: 20px;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }
}
.share-button {
  background-color: #088837; /* WhatsApp green */
  color: white;
  padding: 15px 20px; /* Adjust the padding as needed */
  border: none;
  border-radius: 5px;
  font-family: 'poppins','sans-serif';
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px; /* Additional bottom margin for spacing */
  transition: background-color 0.3s; /* Smooth background color transition */
}

.share-button:hover {
  background-color: #1DAF5A; /* Darker green on hover */
}


.login-message p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.login-message a {
  color: #007BFF;
  font-weight: 500;
  text-decoration: none;
  margin-left: 5px;
  transition: color 0.3s ease;
}

.login-message a:hover {
  color: #0056b3;
  text-decoration: underline;
}
