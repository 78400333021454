/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&family=Merriweather:wght@400;700&display=swap');

/* Container Styling */
.privacy-container {
   
   
    background-color: #ffffff;
    padding: 50px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    font-family: 'Nunito', sans-serif;
    color: #2f2f2f;
    line-height: 1.8;
    transition: all 0.3s ease;
}

.privacy-container:hover {
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

/* Header Styling */
.privacy-header h1 {
    text-align: center;
    color: #1c1c1e;
    font-size: 2.8rem;
    font-family: 'Merriweather', serif;
    margin-bottom: 20px;
}

/* Effective Date Styling */
.privacy-effective-date p {
    text-align: center;
    color: #626567;
    margin-bottom: 40px;
    font-size: 1.15rem;
    font-style: italic;
}

/* Section Styling */
.privacy-section {
    margin-bottom: 40px;
}

/* Section Header Styling */
.section-header h2 {
    color: #bd0045;
    font-size: 1.85rem;
    margin-bottom: 15px;
    font-family: 'Nunito', sans-serif;
    border-bottom: 3px solid #eaecee;
    padding-bottom: 8px;
}

/* Section Content Styling */
.section-content p {
    line-height: 1.85;
    margin-bottom: 20px;
    font-size: 1.1rem;
    text-align: justify;
}

/* List Styling within Sections */
.section-list ul {
    list-style-type: square;
    padding-left: 30px;
    margin-bottom: 20px;
}

.section-list li {
    margin-bottom: 12px;
    font-size: 1.05rem;
}

/* Contact Details Styling */
.contact-details p {
    margin-bottom: 12px;
    font-size: 1.1rem;
    font-weight: 600;
}

/* Link Styling */
.privacy-container a {
    color: #16a085;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
}

.privacy-container a:hover {
    color: #0e6655;
    text-decoration: underline;
}

/* Button Styling */
.privacy-container .btn {
    display: inline-block;
    padding: 12px 24px;
    font-size: 1rem;
    color: #fff;
    background-color: #16a085;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.privacy-container .btn:hover {
    background-color: #138d75;
}

/* Responsive Design */
@media (max-width: 768px) {
    .privacy-container {
        padding: 30px;
        
    }

    .privacy-header h1 {
        font-size: 2.3rem;
    }

    .section-header h2 {
        font-size: 1.6rem;
    }

    .section-content p, 
    .section-list li,
    .contact-details p {
        font-size: 1rem;
    }
}

/* Animation */
@keyframes slideIn {
    from { opacity: 0; transform: translateX(-30px); }
    to { opacity: 1; transform: translateX(0); }
}

.privacy-container {
    animation: slideIn 0.9s ease-in-out;
}
