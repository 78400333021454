.redirect-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .redirect-container h1 {
    font-size: 2rem;
    color: #333;
  }
  
  .redirect-container p {
    margin-top: 1rem;
    font-size: 1rem;
    color: #555;
  }
  
  .redirect-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .redirect-container a:hover {
    text-decoration: underline;
  }
  