/* Styles/PaymentDetailModal.css */

.hat-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ball-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
}

.ball-content.success {
    border: 2px solid #4caf50; /* Green border for success */
}

.ball-content.error {
    border: 2px solid #f44336; /* Red border for error */
}

.status-icon {
    font-size: 24px; /* Adjust size of icons */
    margin-right: 10px;
}

.success-icon {
    color: #4caf50; /* Green color for success icon */
}

.error-icon {
    color: #f44336; /* Red color for error icon */
}

.ball-close-button {
    background-color: #F37254; /* Customize button color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.ball-close-button:hover {
    background-color: #D95F43; /* Darker shade on hover */
}
