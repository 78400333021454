

/* Explore Container */
.explore-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #fcf6ff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 30px auto;
}

/* Heading styles */
.explore-heading {
  font-size: 2rem;
  font-weight: bold;
  color: #b41c56;
  margin-bottom: 15px;
}

/* Description styles */
.explore-description {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
}

/* Button styles */
.explore-button {
  background-color: #4CAF50;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  font-family: sans-serif;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-button:hover {
  background-color: #45a049;
  transform: translateY(-3px);
}

.explore-button svg {
  margin-right: 10px;
}

/* Footer styles */
.explore-footer {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #777;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .explore-container {
    padding: 15px;
    max-width: 95%;
  }

  .explore-heading {
    font-size: 1.8rem;
  }

  .explore-description {
    font-size: 1rem;
  }

  .explore-button {
    font-size: 1rem;
    padding: 12px 25px;
  }
}
