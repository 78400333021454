.recently-viewed-wrapper {
  padding: 20px;
}

.recently-viewed-header {
  margin-bottom: 20px;
}

.profiles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the size as needed */
  gap: 16px;
}

.profile-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s;
}

.profile-card:hover {
  transform: scale(1.05);
}

.profile-photo-container {
  height: 150px; /* Fixed height for uniformity */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5; /* Placeholder background */
}

.profile-photo {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.profile-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  font-size: 14px;
  height: 100%; /* Fill the container */
}

.profile-name {
  font-size: 18px;
  margin: 10px 0;
}

.profile-info {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}

.info-label {
  font-weight: bold;
}

.info-value {
  color: #555;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .profiles-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Smaller min-width */
    gap: 12px; /* Smaller gap */
  }

  .profile-card {
    font-size: 14px; /* Smaller font size */
  }

  .profile-name {
    font-size: 16px; /* Smaller name font size */
  }

  
  .profile-info {
    padding: 8px 10px; /* More comfortable padding */
    flex-direction: column; /* Stack info vertically on smaller screens */
    align-items: flex-start; /* Align text to the left */
  }
}

@media (max-width: 480px) {
  .profiles-grid {
    grid-template-columns: 1fr; /* Single column layout for very small screens */
  }

  .profile-card {
    font-size: 12px; /* Even smaller font size */
  }

  .profile-name {
    font-size: 14px; /* Adjust name font size for small screens */
  }
}
