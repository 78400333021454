/* src/styles/FAQ.css */

/* Container */
.faq-container {
    background-color: #f8f9fa; /* Light background color */
    padding: 40px 20px; /* Padding */
    max-width: 1200px; /* Maximum width */
    margin: 30px auto; /* Centered */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
    font-family: 'Nunito', sans-serif; /* Font style */
}

/* Heading */
.faq-heading {
    color: #343a40; /* Dark grey */
    text-align: center; /* Centered heading */
    margin-bottom: 20px; /* Space below */
    font-size: 2rem; /* Font size */
}

/* FAQ List */
.faq-list {
    margin-top: 20px; /* Space above */
}

/* FAQ Item */
.faq-item {
    background-color: #ffffff; /* White background */
    border-radius: 8px; /* Rounded corners */
    margin-bottom: 15px; /* Space between items */
    transition: box-shadow 0.3s ease; /* Transition for shadow */
}

/* FAQ Question */
.faq-question {
    padding: 15px; /* Padding */
    display: flex; /* Flex display */
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center align items */
    cursor: pointer; /* Pointer on hover */
}

/* FAQ Question Heading */
.faq-question h2 {
    margin: 0; /* Remove margin */
    color: #007bff; /* Primary color */
    font-size: 1.25rem; /* Font size */
}

/* Toggle Icon */
.faq-toggle {
    font-size: 1.5rem; /* Font size for toggle */
    color: #6c757d; /* Grey color */
}

/* FAQ Answer */
.faq-answer {
    padding: 15px; /* Padding */
    font-size: 1rem; /* Font size */
    color: #495057; /* Dark grey */
    border-top: 1px solid #e9ecef; /* Top border */
}

/* Responsive Design */
@media (max-width: 768px) {
    .faq-heading {
        font-size: 1.5rem; /* Smaller heading size */
    }

    .faq-question h2 {
        font-size: 1rem; /* Smaller question size */
    }
}
.register-button {
    background-color: #037c80; /* Orange color */
    color: white;
    padding: 12px 20px;
    text-align: center;
    font-family: 'poppins','sans-serif';
    text-decoration: none; /* Remove underline */
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .register-button:hover {
    background-color: #0a4f52; /* Darker orange on hover */
  }
  