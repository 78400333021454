/* src/Styles/UserDetail.css */

:root {
    --primary-color: #004d40; /* Deep teal */
    --secondary-color: #ffb74d; /* Warm gold */
    --background-color: #f9f9f9; /* Light gray */
    --content-bg-color: #ffffff; /* White */
    --text-color: #333333; /* Dark gray for text */
    --border-color: #eeeeee; /* Light gray for borders */
    --button-bg-color: #00796b; /* Teal for buttons */
    --button-hover-color: #004d40; /* Darker teal for hover effect */
    --heading-color: #004d40; /* Deep teal for headings */
  }
  
  .user-detail-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--background-color);
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Standard */
  }
  
  
  
  .back-button {
    background-color: var(--button-bg-color);
    color: #ffffff;
    border: none;
    padding: 12px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: var(--button-hover-color);
  }
  
  .profile-container {
    margin-top: 20px;
  }
  
  .profile-name {
    font-size: 36px;
    font-weight: 700;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    margin-bottom: 20px;
    color: var(--heading-color);
  }
  
  .profile-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .profile-info-section {
    flex: 1;
    min-width: 260px;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid var(--border-color);
    border-radius: 8px; /* Rounded corners */
    background-color: #ffffff; /* White background for each section */
  }
  .profile-info-section strong {
    font-weight: 600;
    color: #333;
    font-size: 1rem;
    
  }
  
  .profile-info-section h2 {
    font-size: 22px;
    color: var(--primary-color);
    border-bottom: 2px solid var(--secondary-color);
    padding-bottom: 8px;
    margin-bottom: 12px;
  }
  
  .profile-info-section p {
    font-size: 16px;
    margin: 6px 0;
    color: var(--text-color);
    font-family: 'poppins','sans-serif';
  }
  @media (max-width: 1200px) {
  .profile-name {
    font-size: 30px; /* Adjusted size */
  }

  .profile-info-section h2 {
    font-size: 22px; /* Maintain size for better visibility */
  }

  .profile-info-section p {
    font-size: 16px; /* Maintain size for better readability */
  }
}

@media (max-width: 992px) {
  .profile-info {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .profile-name {
    font-size: 28px; /* Smaller but still visible */
  }

  .profile-info-section h2 {
    font-size: 22px; /* Slightly smaller */
  }

  .profile-info-section p {
    font-size: 16px; /* Maintain size */
  }

  .medium-user-photo {
    padding: 10px;
    width: 70vw; /* Slightly wider for smaller screens */
  }
}



/* Animation for the heart when liked */
@keyframes heart-pulse {
  0% {
    transform: scale(1);
    color: #fff;
  }
  50% {
    transform: scale(1.2);
    color: #ffeb3b; /* Yellow color to highlight */
  }
  100% {
    transform: scale(1);
    color: #fff;
  }
}


.photo-container {
  display: flex;
  padding-top: 10px;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.medium-user-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px; /* Adjust width as needed */
 
  
  overflow: hidden;
  background-color: #f0f0f0; /* Light gray background */
  
}

.medium-photo {
  width: 100%; /* Makes the image fill the container */
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area */
}

.medium-placeholder-photo {
  font-size: 1rem; /* Font size for the placeholder text */
  color: #888; /* Gray color for the placeholder text */
  text-align: center; /* Centers the text horizontally */
}
@media (max-width: 768px) {
  .medium-user-photo {
    padding: 10px;
    width: 60vw; /* Adjust width for smaller screens */
  }
}


.user-detail-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.user-detail-content a {
  color: #007BFF;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.user-detail-content a:hover {
  color: #0056b3;
  text-decoration: underline;
}
/* Button styles */
.show-contact-button {
  background-color: #b53109; /* Bootstrap primary color */
  color: #fff;               /* White text color */
  padding: 10px 20px;        /* Padding for top/bottom and left/right */
  border: none;              /* Remove default border */
  border-radius: 5px;       /* Rounded corners */
  font-size: 1rem;           /* Font size */
  cursor: pointer;           /* Pointer cursor on hover */
  
}

.show-contact-button:hover {
  background-color: #ca471f; /* Darker shade for hover effect */
 
}
.time {
  font-size: 16px;              /* Set font size */
  font-weight: bold;            /* Make the text bold */
  color: #FF4500;               /* Orange-red color for visibility */
  background-color: #f0f0f0;    /* Light gray background for contrast */
  padding: 5px 10px;            /* Add some padding around the text */
  border-radius: 5px;           /* Rounded corners */
  display: inline-block;         /* Make it inline-block to respect padding and border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
  font-family: 'poppins','sans-serif';
  font-weight: 500;
  margin-top: 10px;             /* Add a gap at the top */
}

/* Optional hover effect */
.time:hover {
  background-color: #e0e0e0;    /* Darken background on hover */
}

