/* General Form Styles */
.registration-form {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-nav-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  
  font-family: poppins;
  color: white;
  background-color: #6c63ff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Specific Styles for Previous Button */
.prev-btn {
  background-color: #ff6c63; /* Red for Previous */
}

.prev-btn:hover {
  background-color: #d75450;
}

/* Specific Styles for Next Button */
.next-btn {
  background-color: #6c63ff; /* Purple for Next */
}

.next-btn:hover {
  background-color: #5a54d7;
}

/* Specific Styles for Submit Button */
.submit-btn {
  background-color: #4caf50; /* Green for Submit */
}

.submit-btn:hover {
  background-color: #43a047;
}

/* Responsive Button Styling */
@media screen and (max-width: 600px) {
  .form-navigation {
    flex-direction: row; /* Side by side layout */
    justify-content: space-between; /* Space between buttons */
    gap: 10px; /* Add some space between buttons */
  }

  .form-nav-btn {
    width: 38%; /* Nearly half width for both buttons */
    padding: 12px; /* Decreased padding */
    font-size: 14px; /* Decreased font size */
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height */
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.2); /* Light color */
  border-top: 8px solid #007bff; /* Primary color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height */
  text-align: center;
}

.success-container h2 {
  color: #28a745; /* Green color for success */
}

.success-container p {
  margin-top: 10px;
  font-size: 1.2em;
}

.step-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 600px;
  margin: auto;
}
.step-title {
  text-align: center;
  color: #c90a4d;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 600;
}


.form-label {
  font-weight: bold;
  color: #333;
}

.form-input,
.form-select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'poppins','sans-serif';
  box-sizing: border-box;
}

.form-input:focus,
.form-select:focus {
  border-color: #4A90E2;
  outline: none;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

@media (max-width: 600px) {
  .step-container {
    padding: 20px;
  }

  .step-title {
    font-size: 20px;
  }

  .form-input,
  .form-select {
    font-size: 14px;
  }

  button {
    font-size: 14px;
  }
}
/* Color Palette */
:root {
  --primary-color: #8e44ad;  /* Rich purple */
  
  --background-color: #fafafa; /* Soft background */
  --text-color: #2c3e50; /* Deep grey for readability */
}


.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(15, 80, 92);
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 4px solid var(--secondary-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-title {
  font-size: 2.0rem;
  color: rgb(255, 241, 241);
  text-align: center;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1.5px;
}

/* Mobile Responsiveness */
@media only screen and (max-width: 768px) {
  .header-title {
      font-size: 1.2rem;  /* Slightly smaller for mobile screens */
  }

  .header-container {
      padding: 15px;
  }
  
  
}
.yo {
  display: flex;              /* Use flexbox for centering */
  justify-content: center;    /* Center horizontally */
  align-items: center;        /* Center vertically */
  padding: 20px;             /* Add some padding */
   
}

.yo p {
  font-size: 16px;           /* Adjust font size */
  color: #333;               /* Dark text color */
  margin: 0;                 /* Remove default margin */
  font-weight: 500;
  font-family: 'poppins','sans-serif';
}

.lo {
  color: #0b498b;            /* Link color (bootstrap primary) */
  text-decoration: none;      /* Remove underline */
  transition: color 0.3s;    /* Smooth color transition */
}

.lo:hover {
  color: #0056b3;            /* Darker shade on hover */
  text-decoration: underline; /* Underline on hover */
}
@media (max-width: 600px) {
  .yo p {
    font-size: 0.875em; /* Slightly smaller font size on small screens */
  }
}
