/* SettingsPage.css */

.settings-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
  }
  
  .settings-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .settings-form {
    width: 100%;
    max-width: 500px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .update-password-heading {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .update-password-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
  }
  
  .update-password-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 600px) {
    .settings-form {
      padding: 10px;
    }
  
    .update-password-button {
      padding: 8px;
      font-size: 14px;
    }
  }
  