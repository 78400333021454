/* src/styles/ContactUs.css */

/* Container */
.contact-us-container {
    background-color: #fefefe; /* Light Background */
    padding: 30px 15px; /* Reduced padding for mobile */
    max-width: 800px;
    margin: 20px auto; /* Reduced margin */
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* Heading */
.contact-us-heading {
    color: #34495e; /* Dark Grey */
    margin-bottom: 10px;
    font-size: 2rem; /* Slightly reduced font size */
    font-family: 'Merriweather', serif;
    border-bottom: 2px solid #3498db; /* Underline */
    padding-bottom: 10px;
}

/* Description */
.contact-us-description {
    color: #666; /* Slightly Darker Grey */
    margin-bottom: 20px; /* Reduced margin */
    font-size: 1rem; /* Reduced font size */
    font-family: 'Nunito', sans-serif;
}

/* Contact Details */
.contact-details {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 15px; /* Reduced gap */
}

/* Contact Item */
.contact-item {
    background-color: #ffffff; /* White */
    padding: 15px; /* Reduced padding */
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    flex: 1 1 250px; /* Responsive width */
    max-width: 300px; /* Reduced max-width */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}



/* Icon */
.icon {
    font-size: 40px; /* Slightly smaller */
    margin-bottom: 10px; /* Reduced margin */
}

/* Phone Icon */
.phone-icon {
    color: #3498db; /* Blue */
}

/* Chat Icon */
.chat-icon {
    color: #25D366; /* WhatsApp Green */
}

/* Contact Item Heading */
.contact-item-heading {
    color: #2c3e50; /* Dark Blue */
    font-size: 1.5rem; /* Slightly reduced font size */
    margin-bottom: 5px; /* Reduced margin */
    font-family: 'Merriweather', serif;
}

/* Contact Item Description */
.contact-item-description {
    color: #7f8c8d; /* Grey */
    font-size: 1rem; /* Reduced font size */
    margin-bottom: 10px; /* Reduced margin */
}

/* Button Styles */
.button {
    display: inline-block;
    padding: 10px 20px; /* Reduced padding */
    border-radius: 30px; /* Rounded buttons */
    text-decoration: none;
    color: #fff;
    font-size: 0.9rem; /* Reduced font size */
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Call Button */
.call-button {
    background-color: #1d5a83; /* Blue */
    font-family: Nunito;
}

.call-button:hover {
    background-color: #2980b9; /* Darker Blue */
    transform: scale(1.03); /* Slight scale effect */
}

/* Chat Button */
.chat-button {
    background-color: #0b672c; /* WhatsApp Green */
    font-family: Nunito;
}

.chat-button:hover {
    background-color: #1DA247; /* Darker Green */
    transform: scale(1.03); /* Slight scale effect */
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-us-container {
        padding: 20px 10px; /* Further reduced padding */
    }

    .contact-details {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items */
    }

    .contact-item {
        width: 100%; /* Full width on smaller screens */
        margin: 10px 0; /* Margin for spacing */
    }

    .icon {
        font-size: 45px; /* Slightly larger icons for visibility */
    }
}
