.forgot-password-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  background: #ffffff; /* Ensure a solid background */
}

.forgot-password-heading {
  font-size: 28px;
  color: #f12711;
  margin-bottom: 15px;
  font-weight: bold;
}

.forgot-password-description {
  font-size: 16px;
  
  margin-bottom: 25px;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
}

.forgot-password-input {
  padding: 12px;
  margin-bottom: 20px;
  border: 2px solid #626b6a;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.forgot-password-input:focus {
  border-color: #b65b51;
  outline: none;
}

.forgot-password-button {
  padding: 12px;
  background-color: #f12711;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.forgot-password-button:hover {
  background-color: #e6230d;
  transform: translateY(-2px);
}

.forgot-password-footer {
  margin-top: 25px;
  font-size: 16px;
  
}

.forgot-password-link {
  color: #e6230d;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-password-link:hover {
  color: #f4341f;
  text-decoration: underline;
}

/* Mobile Responsive Styles */
@media (max-width: 480px) {
  .forgot-password-container {
    padding: 20px;  /* Reduced padding for smaller screens */
    margin: 50px auto; /* Adjusted margin */
  }

  .forgot-password-heading {
    font-size: 24px; /* Smaller heading */
  }

  .forgot-password-description {
    font-size: 14px; /* Smaller description text */
  }

  .forgot-password-input {
    font-size: 14px; /* Smaller input text */
    padding: 10px;  /* Adjusted padding */
  }

  .forgot-password-button {
    font-size: 14px; /* Smaller button text */
  }

  .forgot-password-footer {
    font-size: 14px; /* Smaller footer text */
  }
}
.modal-content {
  text-align: center;
}

.modal-content h2 {
  margin: 10px 0;
}

.modal-content p {
  margin: 5px 0;
}

