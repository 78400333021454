/* General container styling */
.user-profile-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background: #f9f9f9;
  font-family: 'poppins','sans-serif';
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Heading styling */
.user-profile-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #bd0045;
  text-align: center;
}

/* User profile details styling */
.user-profile-details {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form group styling */
.form-group {
  margin-bottom: 15px;
}

/* Form label styling */
.form-label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
  color: #555;
}

/* Form select and input styling */
.form-select,
.form-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

/* Button styling */
.btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  border: none;
  transition: background-color 0.3s;
}

.btn-save {
  background-color: #28a745;
}

.btn-save:hover {
  background-color: #218838;
}

.btn-edit {
  background-color: #007bff;
}

.btn-edit:hover {
  background-color: #0056b3;
}


.btn-cancel {
  background-color: #d5220a;
  margin-left: 10px;
}

.btn-cancel:hover {
  background-color: #a01c0b;
}
/* Loading spinner styling */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f0f0f0;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .user-profile-container {
    padding: 10px;
  }

  .user-profile-container h2 {
    font-size: 20px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-select,
  .form-input {
    font-size: 14px;
  }

  .btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}
