/* Subscription Page Styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.subscription-page {
    font-family: 'Poppins', sans-serif;
    margin: 20px;
    padding: 25px;
 
}



.sub-title {
    font-size: 32px;
    color: #9a003b; /* Warm brownish-gold */
    margin-bottom: 25px;
    justify-content: center;
    font-weight: 600;
}

.user-info-section {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 25px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-info-section h3 {
    font-size: 24px;
    margin-bottom: 12px;
    color: rgb(130, 3, 155); /* Darker warm tone */
    font-weight: 600;
}

.user-info-section p {
    font-size: 16px;
    color: #252525; /* Medium warm tone */
}



.available-plans-title {
    font-size: 22px;
    margin-top: 30px;
    color: #b1003e; /* Darker warm tone */
    font-weight: 600;
}

.available-plans-list {
    list-style-type: none;
    padding: 0;
}

.available-plans-list li {
    background-color: #ffe0e0; /* Light pink */
    border-radius: 8px;
    padding: 12px;
    margin: 8px 0;
    color: #6b3a2a; /* Darker warm tone */
    font-size: 18px;
    font-weight: 500;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.available-plans-list li:hover {
    background-color: #ffc0c0; /* Hover pink */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.button-gro {
    margin-top: 30px;
    text-align: center;
}

.upgrade-bun {
    background-color: #c1005d; /* Deep pink */
    color: white;
    border: none;
    border-radius: 50px;
    padding: 12px 25px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.upgrade-bun:hover {
    background-color: #890b48; /* Darker pink hover */
    
}

.error-message {
    color: #ff0000;
    margin-top: 15px;
    font-size: 16px;
}

.payment-history {
    margin-top: 30px;
}

.payment-history-title {
    font-size: 22px;
    color: rgb(255, 81, 0); /* Darker warm tone */
    font-weight: 600;
}

.payment-history-cards {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.payment-history-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
   
}

.payment-history-card:hover {
   
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}

.payment-history-card p {
    margin: 8px 0;
    color: #2a2a2a; /* Medium warm tone */
}

.payment-history-card button {
    background-color: #0762d9; /* Deep pink */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
   
}

.payment-history-card button:hover {
    background-color: #0c4999; /* Darker pink hover */
   
}

/* Modal Styles */
.modal {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



@keyframes slideIn {
    from {
        transform: translateY(-50%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.modal-content h3 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #6b3a2a; /* Darker warm tone */
}

.modal-content p {
    margin-bottom: 25px;
    color: #70534c; /* Medium warm tone */
    font-size: 16px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .subscription-page {
        margin: 15px;
        padding: 15px;
    }

    .subscription-title {
        font-size: 26px;
    }

    .user-info-section h3 {
        font-size: 24px;
    }

    .available-plans-title {
        font-size: 20px;
    }

    .upgrade-bun {
        width: 100%;
    }

    .payment-history-card {
        padding: 15px;
    }
}
.current-plan-section {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.current-plan-section h3 {
    color: #333;
    font-size: 1.5rem;
}

.current-plan {
    font-weight: bold;
    color: rgb(130, 3, 155); /* Adjust this color to match your theme */
}

.payment-details {
    margin-top: 15px;
    padding: 10px;
    border-top: 1px solid #ccc;
}


.payment-details p {
    font-size: 1rem;
    color: #242424;
}
.premium-badge {
    display: inline-block;
    background-color: #f3e6ff; /* Light purple background for a premium feel */
    padding: 15px;
    border-radius: 10px;
    border: 2px solid #8a2be2; /* Deep purple border for premium status */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for elevation */
    text-align: center;
    margin-bottom: 15px;
}

.premium-membership {
    font-size: 1.6rem;
    font-weight: 600;
    color: #8a2be2; /* Strong purple for membership text */
    margin: 0;
    line-height: 1.4;
}

.badge {
    font-weight: bold;
    color: #a020f0; /* Lighter purple to symbolize premium status */
    background-color: #f9e6ff; /* Light purple background */
    padding: 5px 10px;
    border-radius: 8px;
    display: inline-block;
    margin-left: 5px;
    border: 1px solid #a020f0; /* Border in a similar purple tone */
}
.expiry-date {
    font-weight: bold;
    color: #3c0074; /* Deep purple for emphasis on the expiry date */
}

